import CommonLayout from '@src/layouts/common/CommonLayout';
import LoginLayout from '@src/layouts/login/LoginLayout';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

const meta = {
	title: 'YULBGM',
	siteDescription: 'YULCOMPANY Inc',
	pageUrl: 'https://www.yulbgm.com',
};

export default function Login()
{
    return (
        <CommonLayout {...meta} onlyHeader={ true }>
            <LoginLayout />
        </CommonLayout>
    )
}

export async function getServerSideProps(context: any)
{
    return {
        props: {
            ...(await serverSideTranslations(context.locale ?? "en", ['common', 'header'])),
        }
    };
}