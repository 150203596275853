import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { P } from '../../components/atoms';
import { useAuthContext, withAuthContext } from '@src/context/Auth';
import { t_usr_info } from '@src/types/tables';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'next-i18next';

declare var window: any;

function LoginLayout()
{
	const { t } = useTranslation("common");
	const [ usrId, setUsrId ] = useState("");
    const [ usrPw, setUsrPw ] = useState("");

    const {
		state: { isAuthenticating },
		action: {
			signIn,
		},
	} = useAuthContext();

    async function handleSubmitLogin(event: React.FormEvent<HTMLFormElement>)
	{
		event.preventDefault();

		if (!usrId || !usrPw)
			alert("아이디와 비밀번호를 입력해주세요.")
		else
		{
			const loginBody = { usrId, usrPw };

			try
			{
				signIn(loginBody as unknown as t_usr_info);
			}
			catch (e)
			{
				console.log(e);
			}
		}
	}

	return (
		<Wrapper>
			<LoginForm onSubmit={ handleSubmitLogin }>
                <Text style={{ margin: "60px auto", lineHeight: 1, cursor: "default", color: "#fff" }}>{ "로그인" }</Text>
                <Input type="text" placeholder={ "아이디" } onChange={(e) => { setUsrId(e.target.value) }} />
                <Input type="password" placeholder={ "비밀번호" } onChange={(e) => { setUsrPw(e.target.value) }} />
                <ConfirmButton onClick={ handleSubmitLogin }>
                    <Text>{ "로그인" }</Text>
                </ConfirmButton>
            </LoginForm>
		</Wrapper>
	);
}

export default withAuthContext(LoginLayout);

const Wrapper = styled.div`
	width: 360px;
	display: flex;
 	flex-direction: column;
	margin: 0 auto;
	padding: 0 20px;
	border-radius: 8px;
	font-family: 'Spoqa Han Sans Neo';

`;

// const Wrapper = styled.div`
// 	width: 360px;
// 	display: flex;
// 	flex-direction: column;
// 	padding: 20px;
// 	border-radius: 8px;
// 	background: #FFF; 
// 	position: absolute;
// 	top: 78px;
// 	z-index: 2;

// 	* {
// 		margin-bottom: 12px;
// 	};
// `;

const LoginForm = styled.form`
	width: 100%;
	margin: 0;
	font-size: 0;
`;

const Text = styled(P)`
	width: fit-content;
	color: #271565;
	font-size: 20px;
	font-weight: 700;
	line-height: 45px; 
	flex: 1;
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const Input = styled.input`
	width: 320px;
	height: 48px; 
	padding: 0 16px;
	margin-bottom: 12px;
	border-radius: 8px;
	border: 1px solid #999;
	background: #FFF; 
	color: #000;
	text-align: left;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: normal; 
`;

const ConfirmButton = styled(Button)`
	width: 147px;
	height: 40px; 
	margin: 48px 0;
	border-radius: 8px;
	border: 1px solid #999;
	background: #FFF; 
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

	p {
		font-size: 16px;
        line-height: 1;
	};
`;